// @function hsl($h, $s, $l) {
//     @return unquote('hsl(#{$h}, #{$s}, #{$l})');
// }
// @function hsla($var, $a) {
//     @return unquote('hsla(#{$var}, #{$a})');
// }
@function hsla($h, $s, $l, $a) {
  @return unquote('hsla(#{$h}, #{$s}, #{$l}, #{$a})');
}




// Responsive Media Query ( html-font-size = 62.5% = 1rem = 10px )
$media-u:               "(min-width: 1600px)";      // media query > 1600     // container 1570
$media-bx:              "(max-width: 1600px)";      // media query < 1600     // container 1170
$media-b-bx:            "(max-width: 1400px)";      // media query < 1600     // container 1170
$media-b:               "(max-width: 1280px)";      // media query < 1280     // container 97
$media-m:               "(max-width: 1080px)";      // media query < 1080     // container 74
$media-sm:              "(max-width: 860px)";       // media query < 860      // container 60
$media-s:               "(max-width: 640px)";       // media query < 640      // container 46
$media-sx:              "(max-width: 480px)";       // media query < 480      // container 100%
$media-sxx:             "(max-width: 360px)";       // media query < 360      // container 100%

$media-bx-min:          "(min-width: 1601px)";      // media query > 1600
$media-b-bx-min:        "(min-width: 1401px)";      // media query > 1400
$media-b-min:           "(min-width: 1281px)";      // media query > 1280
$media-m-min:           "(min-width: 1081px)";      // media query > 1040
$media-sm-min:          "(min-width: 861px)";       // media query > 860
$media-s-min:           "(min-width: 641px)";       // media query > 640
$media-sx-min:          "(min-width: 481px)";       // media query > 480
$media-sxx-min:         "(min-width: 361px)";       // media query > 360

$media-motion:          "screen and (prefers-reduced-motion: no-preference)";



// Width
$w-media-u:             157rem;
$w-media-bx:            117rem;
$w-media-b:             97rem;
$w-media-m:             74rem;
$w-media-sm:            60rem;
$w-media-s:             46rem;
$w-media-sx:            100%;

// Height
$h-header:              16rem;



// Gutter
$gutter:                4rem;





// Colors
// Color Names -> http://chir.ag/projects/name-that-color/
/* Used colors

#ff6300 // hsl(23, 100%, 50%)

#3f3d3d // hsl(0, 2%, 24%) --> #404040 // hsl(0, 0%, 25%)
#403d3d // hsl(0, 2%, 25%) --> #404040 // hsl(0, 0%, 25%)


#777777 // hsl(0, 0%, 47%) --> #737373 // hsl(0, 0%, 45%)
#707070 // hsl(0, 0%, 44%) --> #737373 // hsl(0, 0%, 45%)

#e0e0e0 // hsl(0, 0%, 88%)

#fcfafa // hsl(0, 25%, 98%) --> #fafafa // hsl(0, 0, 98%)

#000000 // hsl(0, 0%, 0%)
#ffffff // hsl(0, 0%, 100%)

#612600 // hsl(24, 100%, 19%)
#94006f // hsl(315, 100%, 29%)
#403d3d // hsl(0, 2%, 25%)

#f7f0f0 // hsl(0, 30%, 95%) --> #fff9f5 // hsl(23, 100%, 98%) --> #fff6f0 // hsl(23, 100%, 97%)
#ff8133 // hsl(23, 100%, 60%)

*/

:root {

    --c-black:              #000;
    --c-white:              #fff;

    --c-grey-medium:        #404040;
    --c-grey-light:         #737373;
    --c-grey-lighter:       #e0e0e0;
    --c-grey-lighest:       #fafafa;

    --c-bg:                 var(--c-grey-lighest);
    --c-fg:                 var(--c-black);

    --c-accent:             #ff6300;
    --c-accent-light:       #ff8133;
    --c-accent-lighest:     #fff6f0;

    --c-link:               var(--c-accent);
    --c-link-hover:         var(--c-accent);

    --c-error:              #c00;

    --c-modal:              var(--c-black);

    --c-mask1:              #612600;
    --c-mask2:              #94006f;
    --c-mask3:              #403d3d;
    --c-mask4:              #ff6300;

    // gutter
    --gutter:               4rem;

    // height
    --h-header:             16rem;

    // width
    --w-body:               190.3rem;
    --w-max:                160rem;

    --content-left:         calc( var(--gutter) );
    @media #{$media-b-min} {
        --content-left:         calc( var(--gutter)*2 );
    }
    @media #{$media-bx-min} {
        --content-left:      calc( var(--gutter)*4 );
    }

    --pattern-width:        100%;
    @media #{$media-m-min} {
        --pattern-width:        30rem;
    }
    @media #{$media-b-bx-min} {
        --pattern-width:        38rem;
    }



    // font
    // AdobeGaramonPro:wght@400;600;700 -> loaded via Typekit Font
    // Aileron:wght@300;400;600;700 -> loaded via Typekit Font
    // .tk-adobe-garamond-pro { font-family: "adobe-garamond-pro",serif; }
    // .tk-aileron { font-family: "aileron",sans-serif; }

    --f-title:              "adobe-garamond-pro", serif;
    --f-text:               "aileron", sans-serif;

    --f-normal:             normal; // 400
    --f-bold:               bold; // 700

    --f-300:                300;
    --f-400:                var(--f-normal);
    --f-600:                600;
    --f-700:                var(--f-bold);

}
