/*------------------------------------*\
    Post Type: Articoli ( post )
    General
\*------------------------------------*/

.blog,
.archive.date,
.archive.category,
.single-post {





    .post--intro {

        margin-top: var(--h-header);

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);
            padding-top: 0;

        }


        .sidebar {

            margin-top: $gutter;
            display: flex;
            gap: $gutter*.5;

            .widget {

                .title { display: none; }// .title

                select {
                    padding: 1rem;
                }

            }// .widget

        }// .sidebar



    }// .post--intro





    .post--content {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);
            padding-top: 0;

        }

    }// .post--content





}// .blog









/*------------------------------------*\
    Post Type: Articoli ( post )
    index.php
\*------------------------------------*/

.blog,
.archive.date,
.archive.category {










}// .blog










/*------------------------------------*\
    Post Type: Articoli ( post )
    single.php
\*------------------------------------*/

.single-post {



    .post--single {

        .inner {}// .inner

        .item {

            display: flex;
            gap: $gutter;

            @media #{$media-b} { flex-direction: column; }

            .image {

                @media #{$media-bx-min} {
                    max-width: 50rem;
                    max-height: 68rem;
                }
                @media #{$media-bx} {
                    max-width: 40rem;
                    max-height: 58rem;
                }
                @media #{$media-b-min} {
                    order: 1;
                    flex-shrink: 0;
                    margin-left: auto;
                }
                @media #{$media-b} {
                    max-width: 70rem;
                    height: 40rem;
                }
                @media #{$media-s} {
                    width: 100%;
                    height: auto;
                }

                .img {

                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 2rem;

                }// .img

            }// .image

            .content {

                max-width: 95rem;

                @media #{$media-b} { max-width: 70rem; }

                .meta {

                    font-size: 1.4rem;
                    font-weight: var(--f-300);
                    margin-bottom: $gutter;

                    .categories {}// .categories

                }// .meta

                .title {

                    font-size: 4rem;
                    line-height: 1.2;
                    margin-bottom: 2rem;

                }// .title

                .summary {

                    background-color: var(--c-grey-lighter);
                    padding: 2rem;
                    margin-block: 2rem;

                }// .summary

                .text {

                    margin-top: 2rem;

                    @media #{$media-sx-min} { padding: 2rem; }

                }// .text

            }// .content

        }// .item

    }// .post--single



}// .single-post
