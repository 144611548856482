/*------------------------------------*\
    Page Template: Attività ( pagina )
    attivita.php
    .page-template-attivita
\*------------------------------------*/

.page-template-attivita {





    // --> pages.scss
    // .page--intro {}// .page--intro





    .page--childs {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-inline: var(--content-left);

        }

        .items {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: $gutter * 2;
            grid-row-gap: $gutter * 3;

            @media #{$media-b} {
                grid-template-columns: repeat(1, 1fr);
                grid-row-gap: $gutter * 2;
            }

            .item {

                max-width: 65rem;
                background-color: var(--c-grey-lighter);
                border-radius: 2rem;

                position: relative;
                display: flex;
                justify-content: space-between;

                border: .1rem solid transparent;

                &:hover,
                &:focus-within {
                    border-color: var(--c-accent);
                }

                @media #{$media-b-min} {

                    &:nth-of-type(odd) { margin-bottom: $gutter; }
                    &:nth-of-type(even) { margin-top: $gutter; }

                }

                @media #{$media-s} {
                    flex-direction: column;
                }

                &:nth-of-type(4n+1) .image::after { background-color: var(--c-mask1); }
                &:nth-of-type(4n+2) .image::after { background-color: var(--c-mask2); }
                &:nth-of-type(4n+3) .image::after { background-color: var(--c-mask3); }
                &:nth-of-type(4n) .image::after { background-color: var(--c-mask4); }

                .image {

                    flex-shrink: 0;
                    width: 26rem;
                    height: 15rem;
                    position: relative;

                    @media #{$media-s-min} {
                        order: 1;
                        height: 100%;
                    }

                    &::after {
                        content : '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        inset: 0;
                        filter: opacity(50%);
                        z-index: 4;
                        border-radius: 2rem;
                    }

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 2rem;

                    }// .img

                }// .image

                .content {

                    padding: $gutter*.5;

                    @media #{$media-s-min} { padding: $gutter*1.5 $gutter; }

                    .title {

                        font-size: 2.5rem;
                        line-height: 1.2;

                        .link {

                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                z-index: 5;
                            }

                        }// .link

                    }// .title

                    .text {}// .text

                    .more {

                        display: inline-block;
                        font-size: 1.4rem;
                        text-decoration: underline;
                        text-decoration-skip-ink: auto;

                    }// .more

                    .title + .text { margin-top: $gutter * .5; }
                    .title + .more { margin-top: $gutter * .5; }
                    .text + .more { margin-top: $gutter * .5; }

                }// .content

            }// .item

        }// .items

    }// .page--childs





}// .page-template-attivita
