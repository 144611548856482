/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/

.footer {

    position: relative;
    z-index: 1;
    background-color: var(--c-bg);

    @media #{$media-m} { margin-top: $gutter * 2; }

    .line {

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
        // max-width: var(--w-max);
        max-width: calc( 100% - var(--content-left) );
        position: absolute;
        top: 0;
        // left: 50%;
        left: 0;
        // transform: translateX(-50%);
        padding-left: var(--content-left);

        &::before,
        &::after {
            content: '';
            display: block;
            height: .1rem;
        }

        &::before {
            background-color: var(--c-grey-light);
            grid-column: span 3;
        }

        &::after {
            background-color: var(--c-accent);
            grid-column: span 1;
        }

    }// .line




    .footer__content {

        padding-top: $gutter * 2;
        padding-right: $gutter;
        padding-left: var(--content-left);
        padding-bottom: $gutter;

        width: 100%;

    }// .footer__content



    .footer__locations {

        margin-bottom: $gutter * 2;

        .items {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: $gutter;
            grid-row-gap: $gutter * 2;

            @media #{$media-b} {}
            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            .item {

                .name {

                    font-size: 2.5rem;
                    line-height: 1.1;

                }// .name

                .inner {

                    margin-top: $gutter * .5;
                    display: flex;
                    gap: $gutter*.5 $gutter;

                    @media #{$media-b} { flex-direction: column; }

                }// .inner

                .logo {

                    width: 8rem;
                    height: 6rem;
                    flex-shrink: 0;

                    @media #{$media-b-min} { margin-top: 5.8rem; }

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;

                    }// .img

                }// .logo

                .content {

                    max-width: 35rem;

                    h6 {
                        font-family: var(--f-text);
                        font-weight: var(--f-normal);
                        font-size: 1.8rem;
                        line-height: 1.1;
                    }
                    h6 + h6 { margin-top: $gutter * .5; }

                    .city {

                        font-size: 3.5rem;
                        line-height: 1.1;

                    }// .city

                    .phone {}// .phone

                    .email {}// .email

                    .web {}// .web

                    .address {

                        line-height: 1.8;

                    }// .address

                }// .content

                .value {

                    display: flex;

                    &::before {
                        flex-shrink: 0;
                        content: '';
                        display: block;
                        width: 2.5rem;
                        height: 2.5rem;
                        margin-right: 2rem;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                }// .value

                .map {

                    margin-top: 1rem;
                    display: flex;
                    align-items: center;
                    margin-left: 5rem;

                    &::before {
                        content: '';
                        display: block;
                        width: 2rem;
                        height: 2rem;
                        margin-right: 1rem;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                }// .map

                .phone .value::before { background-image: url(../images/icon-phone.svg); }
                .email .value::before { background-image: url(../images/icon-email.svg); }
                .web .value::before { background-image: url(../images/icon-web.svg); }
                .address .value::before { background-image: url(../images/icon-address.svg); margin-top: .5rem; }
                .map::before { background-image: url(../images/icon-external.svg); }

            }// .item

        }// .items

    }// .footer__locations



    .footer__policy {

        font-size: 1.4rem;

        .legal {}// .legal

        .items {

            margin-top: $gutter * .25;

            display: flex;
            gap: $gutter * .5;

            @media #{$media-m} { margin-left: 0; }
            @media #{$media-sx} { flex-direction: column; }

            .item {

                font-size: 1.4rem;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                a:hover,
                a:focus-within {
                    color: var(--c-accent);
                }

            }// .item

        }// .items


    }// .footer__policy



    .aside {

        padding: $gutter;
        padding-top: $gutter * 2;



        .footer__cta {

            padding-block: 5rem;
            margin-bottom: $gutter;
            background-image: url(../images/cta-bg.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            position: relative;

            &::before {
                content: '';
                display: block;
                width: 4rem;
                height: 2rem;
                position: absolute;
                top: 2rem;
                right: 1rem;
                background-image: url(../images/cta-email.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }

            .link {

                display: block;
                color: var(--c-white);
                background-color: var(--c-accent);

                padding: $gutter;
                border-radius: 2rem;

                font-size: 2.5rem;
                line-height: 1.5;
                text-align: center;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 5;
                }

            }// .link

        }// .footer__cta



        .footer__social {

            display: flex;
            justify-content: center;
            gap: $gutter;

            @media #{$media-m} { margin-left: 0; gap: $gutter * .5; }
            @media #{$media-sx} { flex-wrap: wrap; }

            .link {

                color: var(--c-white);
                display: block;
                width: 4rem;
                height: 4rem;

                svg {

                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: currentColor;

                }// svg

                &:hover,
                &:focus-within {

                    color: var(--c-accent);

                }

            }// .link

        }// .footer__social



    }// .aside



}// .footer
