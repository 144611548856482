/*------------------------------------*\
    Page Template: Paghe e Contributi ( pagina )
    paghe.php
    .page-template-paghe
\*------------------------------------*/

.page-template-paghe {




    // --> pages.scss
    .page--intro {

        .image {

            .img {

                margin-left: auto;
                display: block;
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 3rem;

            }// .img

        }// .image

        .imager {

            margin-top: $gutter * 2;
            display: flex;
            gap: $gutter;

            @media #{$media-sm} { flex-direction: column; }

            .image {

                flex-shrink: 0;
                max-width: 30rem;

            }// .image

            .content {

                max-width: 60rem;

                .title {

                    font-size: 3.5rem;
                    line-height: 1.4;

                }// .title

                .title + .text { margin-top: $gutter * .5; }

            }// .content

        }// .imager

        .imager2 {

            margin-top: $gutter * 2;

            .image {

                max-width: 80rem;
                margin: auto;

            }// .image

            .text { margin-top: $gutter; }

        }// .imager2

        .aside {

            svg {

                display: block;
                max-width: 80%;
                margin: auto;

                @media #{$media-m-min} { margin-top: 40rem; }
                @media #{$media-m} {
                    max-height: 30rem;
                    margin-block: $gutter * 2;
                }

            }

        }// .aside

    }// .page--intro




    // --> pages.scss
    .page--services {

        .inner { margin-bottom: $gutter * 2; }

    }// .page--services





}// .page-template-paghe
