/*------------------------------------*\
    Page Template: Professionista
    professionista.php
    .page-template-professionista
\*------------------------------------*/

.page-template-professionista {





    // --> pages.scss
    .page--intro {

        .inner {

            // width: 100%;
            // padding: $gutter * 2;
            // padding-left: var(--content-left);
            // padding-top: 0;

            padding-inline: 0;
            width: auto;
            margin-inline: var(--content-left);



            .contents {

                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-gap: $gutter;

                @media #{$media-b} { grid-template-columns: repeat(1, 1fr); }

            }// .contents

            .content1 {

                grid-column: span 3;

                @media #{$media-b} { max-width: 70rem; }

                .text {}// .text

            }// .content1



            .content2 {

                grid-column: span 4;

                display: grid;
                grid-template-columns: repeat(5, 1fr);

                @media #{$media-m} {
                    grid-template-columns: repeat(1, 1fr);
                    max-width: 70rem;
                }

                .image {

                    grid-column: span 3;

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-top-left-radius: 5rem;

                    }// .img

                }// .image

                .keypoints {

                    grid-column: span 2;

                    padding: $gutter;
                    background-color: var(--c-accent);

                    font-family: var(--f-title);
                    font-weight: var(--f-normal);
                    font-size: 1.8rem;
                    line-height: 1.33;

                	ul li {

                		display: flex;
                        gap: 2rem;
                		padding-left: 2rem;

                		&::before {
                			flex-shrink: 0;
                			display: inline-block;
                			content: '';
                            width: 1.5rem;
                            height: .75rem;
                            background-color: var(--c-grey-medium);
                            border-radius: $gutter;
                            margin-top: .75rem;
                            margin-left: -2rem;
                		}

                		&:not(:first-of-type) {
                			margin-top: $gutter * .5;
                		}

                	}

                }// .keypoints

            }// .content2



        }// .inner

    }// .page--intro





}// .page-template-professionista
