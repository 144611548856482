/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/

.page-template-homepage {





    .home--section {

        margin-top: $gutter * 2;

    }// .home--section





    .home--intro {

        width: 100%;
        position: relative;
        display: flex;
        align-items: center;

        position: relative;

        &::after {

            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 2.5rem;
            background-color: var(--c-accent);

        }

        .inner {

            width: 100%;
            padding-top: calc(#{$h-header + $gutter});
            padding-left: var(--content-left);

            @media #{$media-m-min} {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-row-gap: $gutter;
            }
            @media #{$media-m} {
                display: flex;
                flex-direction: column;
                gap: $gutter;
            }

        }

        .title {


            max-width: 75rem;

            font-size: 8rem;
            font-weight: var(--f-normal);
            line-height: 1.1;

            @media #{$media-m-min} { grid-column: span 5; }
            @media #{$media-m} {
                font-size: 6rem;
                margin-right: calc( 4rem + 10rem );
            }
            @media #{$media-s} { font-size: 4rem; }

            span {

                font-weight: var(--f-600);
                display: inline-block;
                position: relative;

                &::before {

                    content: '';
                    display: block;
                    position: absolute;
                    top: 0rem;
                    left: -1.5rem;
                    width: 110%;
                    height: 110%;
                    z-index: -1;
                    // background-color: var(--c-accent);
                    // clip-path: polygon(3% 6%, 0 42%, 9% 90%, 97% 100%, 100% 91%, 90% 0);
                    // clip-path: polygon(15% 0%, 85% 0%, 100% 15%, 100% 85%, 85% 100%, 15% 100%, 0% 85%, 0% 15%);
                    background-image: url(../images/highlight.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                }

            }

        }// .title

        .text {

            grid-column: span 2;
            max-width: 60rem;

            font-size: 1.8rem;
            line-height: 1.8;

            strong { font-weight: var(--f-600); }

            @media #{$media-m-min} { grid-column: span 2; }
            @media #{$media-m} { margin-right: calc( 4rem + 10rem ); }
            @media #{$media-s} { font-size: 1.6rem; }

        }// .text

        .image {

            margin-left: auto;
            display: block;
            width: 100%;
            max-width: 100rem;

            display: flex;

            @media #{$media-m-min} { grid-column: span 3; height: 60rem; }
            @media #{$media-m} { max-width: 80vw; }

            .img {

                display: block;
                width: 100%;
                height: auto;
                object-fit: cover;
                border-top-left-radius: 5vw;

                @media #{$media-m-min} { margin-top: auto; }
                @media #{$media-m} { margin-top: $gutter; }

            }// .img

        }// .image

    }// .home--intro





    .home--who {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);

            @media #{$media-m} { padding-bottom: 0; }

        }

        .content {

            display: flex;
            gap: $gutter;

            @media #{$media-sm} {

                flex-direction: column;
            }

        }// .content

        .title {

            max-width: 30rem;
            font-size: 4.5rem;
            line-height: 1.1;
            font-weight: var(--f-normal);

            @media #{$media-s} { font-size: 3.5rem; }

        }// .title

        .text {

            font-size: 1.8rem;
            font-weight: var(--f-300);
            line-height: 1.8;

            strong { font-weight: var(--f-600); }

            @media #{$media-s} { font-size: 1.6rem; }

        }// .text

        .image {

            display: block;
            width: 100%;
            max-width: 90rem;

            margin-left: calc(-1 * var(--content-left));

            display: flex;

            @media #{$media-m-min} { height: 60rem; }
            @media #{$media-m} { max-width: 80vw; }

            .img {

                margin-top: auto;
                display: block;
                width: 100%;
                height: auto;
                object-fit: cover;
                border-top-right-radius: 4rem;

                @media #{$media-m-min} { margin-top: auto; }
                @media #{$media-m} { margin-top: $gutter; }

            }// .img

        }// .image

        .aside {

            padding-block: $gutter * 2;

            @media #{$media-m} { padding-block: $gutter; }

            .info {

                color: var(--c-white);
                background-color: var(--c-accent);
                border-radius: $gutter;
                padding: $gutter;
                max-width: 30rem;

                text-align: center;

                @media #{$media-m-min} { margin: $gutter; }
                @media #{$media-m} {
                    margin-inline: auto;
                }

                .info_num {

                    font-size: 12rem;
                    line-height: 1.1;
                    font-weight: var(--f-bold);

                    @media #{$media-m} { font-size: 8rem; }

                }// .info_num

                .info_text {

                    font-size: 3.6rem;
                    line-height: 1.1;
                    font-weight: var(--f-normal);

                    @media #{$media-m} { font-size: 2.5rem; }

                }// .info_text

            }// .info

        }// .aside


    }// .home--who





    .home--what {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);

            & > .title {

                font-size: 5.5rem;
                line-height: 1.1;
                font-weight: var(--f-normal);

                @media #{$media-m} { font-size: 4rem; }
                @media #{$media-s} { font-size: 3rem; }

            }// .title

        }

        .items {

            margin-top: $gutter;
            max-width: var(--w-max);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $gutter * 2;

            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            .item {

                position: relative;
                padding-left: 12rem;

                @media #{$media-sm} { padding-left: 0; }

                .title {

                    display: flex;
                    align-items: center;
                    max-width: 45rem;
                    min-height: 5rem;

                    font-family: var(--f-text);
                    font-weight: var(--f-bold);
                    font-size: 2.5rem;
                    line-height: 1.1;

                    @media #{$media-m} { font-size: 2rem; }

                    @media #{$media-sm} {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .icon {

                        flex-shrink: 0;
                        width: 8rem;
                        height: 5rem;
                        color: var(--c-white);
                        background-color: var(--c-accent);
                        border-radius: $gutter;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 3.5rem;
                        line-height: 1;
                        font-weight: var(--f-700);

                        @media #{$media-sm-min} {
                            margin-left: -12rem;
                            margin-right: 4rem;
                        }
                        @media #{$media-sm} { margin-bottom: $gutter * .25; }

                        svg {

                            display: block;
                            width: 100%;
                            height: 100%;
                            max-width: 3rem;
                            max-height: 3.5rem;
                        }

                    }// .icon

                }// .title

                .text {

                    max-width: 45rem;
                    margin-top: $gutter * .5;
                    font-size: 1.8rem;
                    font-weight: var(--f-300);
                    line-height: 1.8;

                    strong { font-weight: var(--f-600); }

                    @media #{$media-m} { font-size: 1.6rem; }

                }// .text

            }// .item

        }// .items


    }// .home--what





    .home--content {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);

        }

    }// .home--content





}// .page-template-homepage
