/*------------------------------------*\
    Error 404
    404.php
    .error404
\*------------------------------------*/

.error404 {



    .page--intro {

        margin-top: var(--h-header);

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);
            padding-top: 0;

        }



        .main_menu {

            margin-top: $gutter;

            .menu {

                .menu-item {

                    display: flex;

                    &:not(:first-of-type) { margin-top: $gutter * .25; }

                    & > a:hover,
                    & > a:focus-within {
                        color: var(--c-accent);
                    }

                    a {

                        display: inline-flex;
                        align-items: center;

                        &::before {

                            display: inline-flex;
                            width: 2.5rem;
                            height: 2.5rem;
                            content: '';
                        }

                    }

                }// .menu-item

                & > .menu-item > a:hover::before,
                & > .menu-item > a:focus-within::before { filter: invert(41%) sepia(47%) saturate(684%) hue-rotate(355deg) brightness(94%) contrast(104%); }

            }// .menu

        }// .main_menu



    }// .page--intro



}// .error404
