/*------------------------------------*\
    Page Template: Attività ( voci )
    attivita-child.php
    .page-template-attivita-child
\*------------------------------------*/

.page-template-attivita-child {




    // --> pages.scss
    .page--intro {

        .imager {

            margin-top: $gutter * 2;
            margin-left: calc( -1 * var(--content-left) );
            margin-right: calc( -1 * var(--pattern-width) - 8rem );

            @media #{$media-m} {

                margin-top: $gutter;
                margin-inline: auto;

            }

        }

        .image {

            display: flex;
            gap: $gutter;

            @media #{$media-m} { flex-direction: column; }

            .text {

                order: -1;
                margin-top: $gutter;
                margin-left: var(--content-left);
                max-width: 45rem;

                @media #{$media-m} {
                    margin-top: 0;
                    margin-left: 0;
                    order: 1;
                }

            }// .text

            .img {

                max-width: 120rem;
                max-height: 44rem;
                margin-left: auto;
                display: block;
                width: 100%;
                height: auto;
                object-fit: cover;
                border-top-left-radius: 5rem;

                @media #{$media-m} {
                    margin-right: -8rem;
                }

            }// .img

        }// .image

    }// .page--intro





    // --> pages.scss
    // .page--services {}// .page--services




    // --> pages.scss
    .page--form {

        .form {

            padding: $gutter;
            background-color: var(--c-white);
            border-radius: 4rem;

        }// .form

    }// .page--form





}// .page-template-attivita-child
