/*------------------------------------*\
    Post Type: Pagine ( page )
    General
    page.php
    *page_template_name*.php
    .page
    .page-template
\*------------------------------------*/

.page-template {





    .page--intro {

        margin-top: var(--h-header);

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);
            padding-top: 0;

        }

    }// .page--intro





    .page--services {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);

            & > .title {

                font-size: 4.5rem;
                line-height: 1.1;

            }// .title

            .title + .text { margin-top: 2rem; }

        }

        .items {

            margin-top: $gutter * 2;
            max-width: var(--w-max);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $gutter * 2;

            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            .item {

                position: relative;
                padding-left: 8rem;

                @media #{$media-sm} { padding-left: 0; }

                .title {

                    display: flex;
                    align-items: center;
                    max-width: 45rem;
                    min-height: 5rem;

                    font-family: var(--f-text);
                    font-weight: var(--f-bold);
                    font-size: 2rem;
                    line-height: 1.25;

                    @media #{$media-m} { font-size: 2rem; }

                    @media #{$media-sm} {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .icon {

                        flex-shrink: 0;
                        width: 5rem;
                        height: 3rem;
                        color: var(--c-white);
                        background-color: var(--c-accent);
                        border-radius: $gutter;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 3.5rem;
                        line-height: 1;
                        font-weight: var(--f-700);

                        @media #{$media-sm-min} {
                            margin-left: -8rem;
                            margin-right: 2rem;
                        }
                        @media #{$media-sm} { margin-bottom: $gutter * .25; }

                        svg {

                            display: block;
                            width: 100%;
                            height: 100%;
                            max-width: 2rem;
                            max-height: 1.5rem;
                        }

                    }// .icon

                }// .title

                .text {

                    max-width: 45rem;
                    margin-top: $gutter * .5;
                    font-size: 1.6rem;
                    font-weight: var(--f-300);
                    line-height: 1.6;

                    strong { font-weight: var(--f-600); }

                    @media #{$media-m} { font-size: 1.6rem; }

                }// .text

            }// .item

        }// .items


        .keypoints {

            padding: $gutter;
            color: var(--c-white);

            font-family: var(--f-title);
            font-weight: var(--f-normal);
            font-size: 1.8rem;
            line-height: 1.33;

        	ul li {

        		display: flex;
                gap: 2rem;
        		padding-left: 2rem;

        		&::before {
        			flex-shrink: 0;
        			display: inline-block;
        			content: '';
                    width: 2.5rem;
                    height: 1.5rem;
                    color: var(--c-white);
                    background-color: var(--c-accent);
                    border-radius: $gutter;
                    margin-top: .75rem;
                    margin-left: -2rem;
        		}

        		&:not(:first-of-type) {
        			margin-top: $gutter * .5;
        		}

        	}

        }// .keypoints

    }// .page--services





    .request {

        position: absolute;
        right: 4rem;
        max-width: 22rem;

        top: calc(var(--h-header) + 4rem );

        @media #{$media-m-min} {

            right: 4rem;
            top: calc(var(--h-header) + 8rem);
            padding-block: 5rem;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-image: url(../images/cta-bg.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: -1;
                transform: scaleX(-1);
            }

        }
        @media #{$media-b-bx-min} { right: 8rem; }
        @media #{$media-sm} { display: none; }

        // position: relative;

        .link {

            display: block;
            color: var(--c-white);
            background-color: var(--c-accent);

            border-radius: 1rem;
            padding: $gutter *.25;
            text-align: center;

            @media #{$media-m} { text-transform: uppercase; }
            @media #{$media-m-min} {
                padding: $gutter *.5;
                font-size: 2.5rem;
                line-height: 1.5;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 5;
            }

        }// .link

    }// .request

    .page--form {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);

        }

        .image {

            max-width: 90rem;
            margin-bottom: $gutter;

            .img {

                display: block;
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 4rem;

            }

        }// .image

        .form {}// .form

    }// .page--form





    .page--content {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);

        }

    }// .page--content





}// .page-template










/*------------------------------------*\
    Page
    page.php
    .page-template-default
\*------------------------------------*/

.page-template-default {


    .page--intro {

        margin-top: var(--h-header);

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);
            padding-top: 0;

        }

    }// .page--intro


}// .page-template-default
