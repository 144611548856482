/*------------------------------------*\
    Page Template: Lo Studio ( chi siamo )
    studio.php
    .page-template-studio
\*------------------------------------*/

.page-template-studio {





    // --> pages.scss
    .page--intro {

        .inner {

            // width: 100%;
            // padding: $gutter * 2;
            // padding-left: var(--content-left);
            // padding-top: 0;

            padding-block: $gutter * 2;
            padding-inline: var(--content-left);
            width: auto;
            margin-inline: var(--content-left);

            &:nth-of-type(1) {

                padding-bottom: 0;
                background-color: var(--c-accent-light);
                background-image: url(../images/pattern.svg);
            	background-size: 100rem;
                background-attachment: fixed;

            }
            &:nth-of-type(2) {

                background-color: var(--c-accent-lighest);

            }



            .image {

                .img {

                    display: block;
                    width: 100%;
                    height: auto;
                    object-fit: cover;

                }// .img

            }// .image



            .content {

                margin-top: $gutter * 2;
                max-width: 90rem;
                margin-inline: auto;

                .title3 {

                    font-family: var(--f-text);
                    font-size: 6.5rem;
                    line-height: 1.1;
                    font-weight: var(--f-bold);
                    color: var(--c-white);
                    text-align: center;
                    margin-bottom: $gutter;

                    @media #{$media-b} { font-size: 5rem; }
                    @media #{$media-m} { font-size: 3rem; }

                }// .title3

                .image {

                    height: 40rem;

                    @media #{$media-m} { height: 30rem; }

                    .img { height: 100%; }

                }// .image

            }// .content



            .content1 {

                display: flex;
                gap: $gutter;

                @media #{$media-m} { flex-direction: column; }

                .image {

                    flex-shrink: 0;
                    max-width: 30rem;
                    height: 100%;
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: -2rem;
                        right: -2rem;
                        width: 100%;
                        height: 15rem;
                        background-color: var(--c-accent);
                        z-index: 0;
                    }

                    .img {
                        position: relative;
                        z-index: 1;
                    }

                }// .image

                .text {}// .text

            }// .content1



            .content2 {

                margin-top: $gutter * 2;
                display: flex;
                gap: $gutter;

                @media #{$media-m} { flex-direction: column; }

                .image {

                    flex-shrink: 0;
                    max-width: 45rem;

                }// .image

                .text {

                    margin-top: auto;

                }// .text

            }// .content2



        }// .inner

    }// .page--intro





}// .page-template-studio
