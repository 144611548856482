/*------------------------------------*\
    Page Template: Contatti ( pagina )
    contatti.php
    .page-template-contatti
\*------------------------------------*/

.page-template-contatti {




    // --> pages.scss
    .page--intro {



        .content--form {

            margin-top: $gutter * 2;

            .title {

                margin-bottom: $gutter;
                font-size: 3.5rem;
                line-height: 1.1;

            }// .title

            .form {

                max-width: 90rem;

            }// .form

        }// .content--form



        .aside {



            .office {

                position: sticky;
                top: var(--h-header);

                color: var(--c-white);
                padding: $gutter;

                .title {

                    font-size: 3.5rem;
                    line-height: 1.1;
                    color: var(--c-accent);

                }// .title

                .infos {

                    .info {

                        margin-top: $gutter * .5;
                        font-size: 2.2rem;
                        line-height: 1.3;
                        overflow-wrap: break-word;

                        .icon {

                            display: block;
                            width: 7.5rem;
                            height: 4.5rem;
                            border-radius: 2rem;
                            background-color: var(--c-accent);
                            padding: 1rem;
                            margin-bottom: 1rem;

                            svg {
                                margin-inline: auto;
                                display: block;
                                width: 100%;
                                height: 100%;
                                max-width: 3rem;
                                max-height: 3rem;
                            }

                        }// .icon

                        .map {

                            display: block;
                            margin-top: 2rem;
                            text-transform: uppercase;
                            font-size: 1.4rem;

                        }// .map

                    }// .info

                }// .infos

            }// .office



        }// .aside



    }// .page--intro





    .page--map {

        .inner {

            width: 100%;
            padding: $gutter * 2;
            padding-left: var(--content-left);

            & > .title {

                margin-bottom: $gutter;
                font-size: 3.5rem;
                line-height: 1.1;

            }// .title

        }

        .items {

            margin-block: $gutter;
            max-width: var(--w-max);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $gutter * 2;

            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            .item {

                position: relative;
                padding-left: 8rem;

                @media #{$media-sm} { padding-left: 0; }

                .title {

                    display: flex;
                    align-items: center;
                    max-width: 35rem;
                    min-height: 5rem;

                    font-family: var(--f-text);
                    font-weight: var(--f-bold);
                    font-size: 2rem;
                    line-height: 1.25;

                    @media #{$media-m} { font-size: 2rem; }

                    @media #{$media-sm} {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .icon {

                        flex-shrink: 0;
                        width: 5rem;
                        height: 3rem;
                        color: var(--c-white);
                        background-color: var(--c-accent);
                        border-radius: $gutter;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 3.5rem;
                        line-height: 1;
                        font-weight: var(--f-700);

                        @media #{$media-sm-min} {
                            margin-left: -8rem;
                            margin-right: 2rem;
                        }
                        @media #{$media-sm} { margin-bottom: $gutter * .25; }

                        svg {

                            display: block;
                            width: 100%;
                            height: 100%;
                            max-width: 2rem;
                            max-height: 1.5rem;
                        }

                    }// .icon

                }// .title

                .text {

                    max-width: 35rem;
                    margin-top: $gutter * .5;
                    font-size: 1.6rem;
                    font-weight: var(--f-300);
                    line-height: 1.6;

                    strong { font-weight: var(--f-600); }

                    @media #{$media-m} { font-size: 1.6rem; }

                }// .text

            }// .item

        }// .items

        .acf-map {

            width: 100%;
            height: 50rem;
            max-width: 120rem;
            border-radius: 2rem;

            & img { max-width: inherit !important; }

        }// .map

    }// .page--map





}// .page-template-contatti
