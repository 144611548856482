/*------------------------------------*\
    Box
    .box
\*------------------------------------*/

.box {}// .box




.pattern--content {

    display: flex;

    @media #{$media-m} { flex-wrap: wrap; }

    .pattern {

        flex-shrink: 0;
        margin-left: auto;
        position: relative;
        display: block;
        width: var(--pattern-width);
        // height: 100%;
        height: auto;

        // @media #{$media-b-bx} { width: 30rem; }
        // @media #{$media-m} { width: 10; }
        // @media #{$media-m} { width: 100%; }

        &::before {

            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        	content: '';
        	display: block;
        	width: 100%;
        	height: 100%;
        	background-color: var(--c-grey-medium);
        	background-image: url(../images/pattern.svg);
        	background-size: 100rem;
            background-attachment: fixed;

        }

    }// .pattern

}// .pattern--content

.pattern--bg {

    position: relative;

    &::before {

        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    	content: '';
    	display: block;
    	width: var(--pattern-width);
    	height: 100%;
    	background-color: var(--c-grey-medium);
    	background-image: url(../images/pattern.svg);
    	background-size: 100rem;
        background-attachment: fixed;

        @media #{$media-m} { width: 0; }

    }

}// .pattern--bg










/*------------------------------------*\
    Title
    .box--title
\*------------------------------------*/

.box--title {

    max-width: 100rem;

    .title {

        font-family: var(--f-text);
        font-weight: var(--f-300);
        font-size: 2rem;
        line-height: 1.4;

    }// .title

    .title2 {

        font-size: 7rem;
        font-weight: var(--f-normal);
        line-height: 1.1;

        @media #{$media-b} { font-size: 6rem; }
        @media #{$media-m} { font-size: 4rem; }

    }// .title2
    .title + .title2 { margin-top: 2rem; }

    .text {

        max-width: 100rem;

    }// .text

    .title + .text { margin-top: 2rem; }
    .title2 + .text { margin-top: 4rem; }

    .policy { max-width: 100rem; margin-top: 4rem; }

}// .box--title










/*------------------------------------*\
    Articoli
    .box--posts
\*------------------------------------*/

.box--posts {

    .inner > .title {

        font-size: 5.5rem;
        line-height: 1.1;
        font-weight: var(--f-normal);
        margin-bottom: $gutter;

        @media #{$media-m} { font-size: 4rem; }
        @media #{$media-s} { font-size: 3rem; }

    }// .title

    .items {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: $gutter * 2;
        grid-row-gap: $gutter * 3;

        @media #{$media-b} {
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: $gutter * 2;
        }

        .item {

            max-width: 65rem;

            position: relative;
            display: flex;
            justify-content: space-between;

            border: .1rem solid transparent;
            border-top-left-radius: 2rem;

            @media #{$media-sm} {
                flex-direction: column;
                border-top-right-radius: 2rem;
            }

            &:hover,
            &:focus-within {
                border-color: var(--c-accent);
                // outline: .1rem dashed var(--c-accent); outline-offset: .2rem;
            }

            &:nth-of-type(4n+1) .image::after { background-color: var(--c-mask1); }
            &:nth-of-type(4n+2) .image::after { background-color: var(--c-mask2); }
            &:nth-of-type(4n+3) .image::after { background-color: var(--c-mask3); }
            &:nth-of-type(4n) .image::after { background-color: var(--c-mask4); }

            .image {

                flex-shrink: 0;
                margin-bottom: $gutter;

                position: relative;
                z-index: 2;

                width: 30rem;
                height: 40rem;
                position: relative;

                @media #{$media-sm} {
                    margin-bottom: 0;
                    width: 100%;
                    height: 25rem;
                }
                @media #{$media-sx} {
                    height: 20rem;
                }

                &::after {
                    content : '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    inset: 0;
                    filter: opacity(50%);
                    z-index: 4;
                    border-radius: 2rem;

                    @media #{$media-sm} {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                .img {

                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 2rem;

                    @media #{$media-sm} {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                }// .img

            }// .image

            .content {

                margin-top: $gutter;
                margin-left: -$gutter;

                padding: $gutter;
                padding-left: $gutter * 2;
                background-color: var(--c-grey-lighter);

                @media #{$media-sm} {
                    margin: 0;
                    padding: $gutter;
                }

                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                .meta {

                    font-size: 1.4rem;
                    font-weight: var(--f-300);
                    margin-bottom: $gutter;

                    @media #{$media-sm} { margin-bottom: $gutter * .5; }

                    .categories {}// .categories

                }// .meta

                .title {

                    font-size: 2.5rem;
                    line-height: 1.2;

                    .link {

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 5;
                        }

                    }// .link

                }// .title

                .text {}// .text

                .more {

                    display: inline-block;
                    font-size: 1.4rem;
                    text-decoration: underline;
                    text-decoration-skip-ink: auto;

                }// .more

                .title + .text { margin-top: $gutter * .5; }
                .title + .more { margin-top: $gutter * .5; }
                .text + .more { margin-top: $gutter * .5; }

            }// .content

        }// .item

    }// .items

}// .box--posts
