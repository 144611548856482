@media screen and (prefers-reduced-motion: no-preference) {

	html,
	body {
		scroll-behavior: smooth;
	}

}

html {
	font-size: 62.5%;
}

	body {

		color: var(--c-fg);
		background-color: var(--c-bg);

		font-family: var(--f-text);
		font-weight: var(--f-normal);
		font-size: 1.6rem;
		line-height: 1.8;
        -webkit-text-size-adjust: 100%;

		max-width: var(--w-body);

		// &.main__nav-opened {
		// 	overflow: hidden;
		// 	height: 100%;
		//
		// 	/* Hide scrollbar for Chrome, Safari and Opera */
		// 	&::-webkit-scrollbar {
		// 		display: none;
		// 	}
		//
		// 	-ms-overflow-style: none;  /* IE and Edge */
		// 	scrollbar-width: none;  /* Firefox */
		//
		// 	 // position: fixed;
		//
		// }// &.main__nav-opened

	}
	// @-moz-document url-prefix() {
    // 	html.main__nav-opened,body.main__nav-opened{ overflow: hidden; }
	// }

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: var(--f-title);
		}

		p {}

		strong {
			font-weight: var(--f-bold);
		}

		a {
			text-decoration: none;
			color: currentColor;

			&:hover {}

		}










/*------------------------------------*\
   general
\*------------------------------------*/

::selection {
	background-color: var(--c-fg);
	color: var(--c-bg);
}

:focus { outline: .1rem dashed var(--c-accent); outline-offset: .2rem; }

.none { display: none; }

.hidden { visibility: hidden; }

.uppercase { text-transform: uppercase; }

.anchor {
    display: block;
    position: relative;
    top: calc(var(--h-header) * -1);
    visibility: hidden;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}





/*------------------------------------*\
   Text
\*------------------------------------*/

.text {

	a {
		color: currentColor;
	}

	ul,
	ol {
		margin: $gutter * .5;
	}

	ol { list-style-type: decimal; }
	ul { list-style-type: disc; }
	li {}

	p + p {
		margin-top: $gutter * .5;
	}

	strong { font-weight: var(--f-bold); }
	em { font-style: italic; }

}// .text





#iub-pp-container { background-color: var(--c-white); }
