/*------------------------------------*\
    Page Template: Lo Studio ( staff )
    staff.php
    .page-template-staff
\*------------------------------------*/

.page-template-staff {





    // --> pages.scss
    .page--intro {

        .inner {

            // width: 100%;
            // padding: $gutter * 2;
            // padding-left: var(--content-left);
            // padding-top: 0;

            padding-block: $gutter * 2;
            padding-inline: var(--content-left);
            width: auto;
            margin-inline: var(--content-left);

            background-color: var(--c-grey-lighter);



            .image {

                .img {

                    display: block;
                    width: 100%;
                    height: auto;
                    object-fit: cover;

                }// .img

            }// .image



            .content {

                margin-top: $gutter;
                margin-inline: auto;

                .image {

                    height: 40rem;

                    @media #{$media-m} { height: 30rem; }

                    .img { height: 100%; }

                }// .image

            }// .content



            .contents {

                margin-top: $gutter;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: $gutter;

                @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            }// .contents

            .content1 {

                .text {}// .text

            }// .content1



            .content2 {

                .image {

                    margin-top: $gutter;
                    max-width: 60rem;

                }// .image

                .text {}// .text

            }// .content2



        }// .inner

    }// .page--intro





}// .page-template-staff
