/*------------------------------------*\
    Header
   #header.header
\*------------------------------------*/

.header {

    position: fixed;
    z-index: 10;

    width: 100%;
    max-width: var(--w-body);
    height: var(--h-header);

    background-color: transparent;

    will-change: background-color;
    transition: background-color .25s ease;

    &.after-scroll { background-color: hsla(0, 0%, 100%, .9); }

    .pattern {

        @media #{$media-m} {

            width: 10rem;

            &::before {
                background-color: transparent;
                background-image: none;
            }

        }

    }

}// .header










/*------------------------------------*\
    Header content
   .header__content
\*------------------------------------*/

.header__content {

    position: relative;
    height: 100%;

    @media #{$media-b} {
        flex-wrap: nowrap;
    }

    .content {

        display: flex;
        align-items: center;
        padding: $gutter;
        width: 100%;
        height: 100%;

    }



    .brand {

        width: 25rem;
        height: 5rem;

        @media #{$media-s} { width: 20rem; }
        @media #{$media-sxx} { width: 15rem; }

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

    }// .brand



    .header_menu {

        margin: auto;

        display: flex;
        align-items: center;

        @media #{$media-m} { display: none; }

        .menu {

            display: flex;
            gap: $gutter;

            .menu-item {

                // font-family: var(--f-title);
                // font-size: 1.8rem;
                text-transform: uppercase;

                &:hover,
                &:focus-within {
                    color: var(--c-accent);
                }

                &.current-menu-item > a,
                &.current-menu-parent > a,
                &.current-menu-ancestor > a,
                &.current-page-ancestor > a,
                &.current_page_parent > a {
                    color: var(--c-accent);
                }

                // @at-root body.single-solution #{&}.menu-item-object-solution > a { color: var(--c-accent); }
                // @at-root body.single-faq #{&}.menu-item-object-faq > a { color: var(--c-accent); }

            }// .menu-item

        }// .menu

    }// .header_menu



    .aside {

        display: flex;
        align-items: center;
        padding: $gutter;
        color: var(--c-white);

    }



    .nav__open {

        margin-left: auto;

        border: none;
        padding: 0;
        background-color: transparent;

        width: 3rem;
        height: 3rem;

        .icon {

            display: block;
            width: 100%;
            height: 100%;
            // stroke: var(--c-white);

            & path {
                @media #{$media-m} { stroke: var(--c-black); }
            }

            &:hover,
            &:focus-within {
                // stroke: var(--c-accent);
            }

        }

    }// .nav__open



}// .header__content










/*------------------------------------*\
    Main Navigation
   nav.main__nav
\*------------------------------------*/

.main__nav {

    touch-action: none;
    color: var(--c-white);
    background-color: var(--c-grey-medium);

    background-image: url(../images/menu-bg.svg);
    background-repeat: repeat;
    background-size: 5rem;
    background-attachment: fixed;

    position: fixed;
    z-index: 99999;
    right: 0;
    top: 0;
    width: 70%;
    height: 100%;
    overflow: auto;

    will-change: transform, opacity, visibility;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s;

    @media #{$media-sm} { width: 100%; }

    :focus { outline: .1rem dashed var(--c-accent); outline-offset: .2rem; }

    /*&::before {

        content: '';
        display: block;
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/menu-bg.svg);
        background-repeat: repeat;
        background-size: 5rem;
        background-attachment: fixed;
        // https://codepen.io/sosuke/pen/Pjoqqp -> #4d4d4d
        // filter: invert(19%) sepia(0%) saturate(3659%) hue-rotate(227deg) brightness(124%) contrast(75%);

    }*/



    .content {

        max-width: var(--w-max);
        padding: $gutter;
        margin: auto;

        display: flex;
        align-items: center;

    }// .content



    .nav__close {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        position: relative;
        order: 1;
        flex-shrink: 0;
        margin-left: auto;
        z-index: 5;
        border: none;
        background-color: transparent;

        @media #{$media-s} {
            width: 4rem;
            height: 4rem;
        }
        &:hover,
        &:focus-within {
            // color: var(--c-accent);
        }

        .icon {

            display: block;
            width: 3rem;
            height: 3rem;
            // stoke: currentColor;

            @media #{$media-s} {
                width: 2rem;
                height: 2rem;
            }

        }// .icon

    }// nav_close



    /*------------------------------------*\
        Main Menu -> main_menu
        Main Navigation
        nav.main_menu
    \*------------------------------------*/

    .main_menu {

        position: relative;
        z-index: 2;

        max-width: var(--w-max);
        padding: $gutter *2;
        margin: auto;
        overflow-x: hidden;

        @media #{$media-b} {
            padding: $gutter;
            padding-top: 0;
        }

    }// .main_menu

    .menu {

        font-family: var(--f-title);

        & > .menu-item {

            &:not(:first-of-type) { margin-top: $gutter; }

            position: relative;

            & > a {

                font-size: 4rem;
                line-height: 1.1;
                font-weight: var(--f-600);

                display: flex;
                align-items: center;
                max-width: 40rem;

                @media #{$media-s} { font-size: 3rem; }

            }

        }

        .menu-item {

            & > a:hover,
            & > a:focus-within {

                color: var(--c-accent);

            }

            &.current-menu-item > a,
            &.current-menu-parent > a,
            &.current-menu-ancestor > a {
                color: var(--c-accent);
            }

            // @at-root body.single-solution #{&}.menu-item-object-solution > a { color: var(--c-accent); }
            // @at-root body.single-faq #{&}.menu-item-object-faq > a { color: var(--c-accent); }

        }// .menu-item

        .sub-menu {

            display: block;
            margin: $gutter;

            @media #{$media-b} { display: none; }
            @media #{$media-b-min} {

                position: absolute;
                top: -3.2rem;
                left: 40rem;

                will-change: transform, opacity, visibility;
                transform: translateX(100%);
                opacity: 0;
                visibility: hidden;
                transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s;

            }

            .menu-item {

                &:not(:first-of-type) { margin-top: $gutter * .5; }

                a {

                    font-size: 2.8rem;
                    line-height: 1.1;

                }

            }

        }// .sub-menu

        // .menu-item-has-children:hover > .sub-menu,
        // .menu-item-has-children:focus > .sub-menu,
        // .menu-item-has-children:focus-within > .sub-menu,

        .menu-item-has-children {

            & > a {

                &::before {

                    content: '';
                    order: 1;
                    flex-grow: 0;
                    display: block;
                    will-change: flex-grow;
                    transition: flex-grow 0.5s ease;

                }

                &::after {

                    content: '';
                    order: 2;
                    display: block;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-top: .2rem solid currentColor;
                    border-left: .2rem solid currentColor;
                    transform: rotate(135deg);
                    margin-left: 2rem;
                }

            }

        }// .menu-item-has-children

        .menu-item-has-children.open > .sub-menu {

            @media #{$media-b} { display: block; }

            @media #{$media-b-min} {
                touch-action: none;
                display: block;
                transform: translateX(0%);
                opacity: 1;
                visibility: visible;
                z-index: 5;
            }

        }

        .menu-item-has-children.open > a {

            &::before {

                @media #{$media-b-min} {
                    content: '';
                    order: 1;
                    flex-grow: 1;
                    display: block;
                    margin-left: 2rem;
                    // margin-right: -1.5rem;
                    height: .2rem;
                    background-color: currentColor;
                }

            }

            &::after {

                @media #{$media-b} { transform: rotate(-135deg); }
                @media #{$media-b-min} { transform: rotate(-45deg); margin-left: 0; }

            }

        }

    }// .menu

    &.open,
    &.toggled-on {

        transform: translateX(0%);
        opacity: 1;
        visibility: visible;

    }

}// .main__nav
